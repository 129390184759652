import ReactDOM from 'react-dom/client';
import React from "react";
// import { ActionButton, AsyncImage, BCol, BRow, Box, Column, Container, DeviceContainer, DeviceFlex, Flex, Link, LinkButton, ListBuilder, Loader, NavigationBar as Nav, NavLink, Row, StateIcon, SwitchActionButton, Text, htmlToElement } from "react-builder";
import { htmlToElement } from '../node_modules/react-builder/dist/Core';
// import { BRow } from '../node_modules/react-builder/dist/widgets/layout/BRow';
// import { BCol } from '../node_modules/react-builder/dist/widgets/layout/BCol';
// import { Loader } from '../node_modules/react-builder/dist/widgets/Loader';
// import { ListBuilder } from '../node_modules/react-builder/dist/widgets/ListBuilder';
// import { Link } from '../node_modules/react-builder/dist/widgets/Link';
// import { SwitchActionButton } from '../node_modules/react-builder/dist/widgets/SwitchActionButton';
// import { StateIcon } from '../node_modules/react-builder/dist/widgets/StateIcon';
// import { Text } from '../node_modules/react-builder/dist/widgets/Text';
// import { Flex } from '../node_modules/react-builder/dist/widgets/Flex';
// import { DeviceFlex } from '../node_modules/react-builder/dist/widgets/DeviceFlex';
// import { Column } from '../node_modules/react-builder/dist/widgets/Column';
// import { Box } from '../node_modules/react-builder/dist/widgets/Box';
// import { Row } from '../node_modules/react-builder/dist/widgets/Row';
// import { ActionButton } from '../node_modules/react-builder/dist/widgets/ActionButton';
// import { NavigationBar as Nav } from '../node_modules/react-builder/dist/widgets/Navbar';
// import { NavLink } from '../node_modules/react-builder/dist/widgets/NavLink';
// import { AsyncImage } from '../node_modules/react-builder/dist/widgets/AsyncImage';
// import { DeviceContainer } from '../node_modules/react-builder/dist/widgets/DeviceContainer';
// import { Container } from '../node_modules/react-builder/dist/widgets/Container';
// import { LinkButton } from '../node_modules/react-builder/dist/widgets/LinkButton';
// import ProductHomepage from './widgets/ProductHomepage';

const e = document.getElementById('root') as HTMLElement;
const splash = document.getElementById('splash') as HTMLElement;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const preloadComponents = {};//{ ProductHomepage};//, BRow, BCol, Loader, ListBuilder, Link, SwitchActionButton, StateIcon, Text, Flex, DeviceFlex, Column, Box, Row, ActionButton, Nav, NavLink, AsyncImage, DeviceContainer, Container, LinkButton };

export const handleImport = (domain: string, componentName: string, module?: string) => {
  console.log(componentName);

  const componentId = (module ? `${module}/` : ``) + componentName;
  return domain == 'intellforce' || domain == 'builder' ? import(`../node_modules/react-builder/dist/widgets/${componentId}.js`)
    .then((e) => e.default) :
    import('./widgets/' + componentId)
      .then((e) => {
        return e.default;
      })
}

const customRender = async (config: { domain: string, componentName: string, module?: string, attributes: any, htmlAttributes: any, element: any, childNodes: any }) => {
  if (config.componentName == 'ProductHomepagePage') {
    const el = await handleImport('intellforce', 'AsyncPage');
    return React.createElement(
      el,
      {
        ...config.htmlAttributes, "data-data": null, key: '', attributes: el.prototype && el.prototype.Props ? new el.prototype.Props().fromJSON(config.attributes) : config.attributes, nodes: config.childNodes as NodeList
      },
      config.element.innerHTML);
  }
  else if (config.componentName == 'ProductHomepagePageV2') {
    const el = await handleImport('intellforce', 'AsyncPageV2');
    return React.createElement(
      el,
      {
        ...config.htmlAttributes, "data-data": null, key: '', attributes: el.prototype && el.prototype.Props ? new el.prototype.Props().fromJSON(config.attributes) : config.attributes, nodes: config.childNodes as NodeList
      },
      config.element.innerHTML);
  }

  return null;
}

htmlToElement(e, '', preloadComponents, handleImport).then((_e: any) => {
  root.render(
    _e
  );
  setTimeout(() => {
    splash.style.display = "block";
  }, 10)
}, customRender)